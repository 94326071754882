import React from "react";
import JacketImage from "../assets/images/jackets.jpeg";
import BootImage from "../assets/images/boots.jpeg";
import JeansImage from "../assets/images/jeans.jpeg";
import SweaterImage from "../assets/images/sweater.jpeg";
import UnderGarmentsImage from "../assets/images/undergarments.jpeg";

const Gallery = () => {
  return (
    <div id="gallery" className="max-w-[1140px] m-auto w-full px-4 py-16">
      <h2 className="text-center text-gray-700 p-4">Gallery</h2>
      <div className="grid sm:grid-cols-5 gap-4">
        <div className="sm:col-span-3 col-span-2 row-span-2">
          <img
            className="w-full h-full object-cover"
            src={JacketImage}
            alt="/"
          />
        </div>
        <div>
          <img className="w-full h-full object-cover" src={BootImage} alt="/" />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={JeansImage}
            alt="/"
          />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={SweaterImage}
            alt="/"
          />
        </div>
        <div>
          <img
            className="w-full h-full object-cover"
            src={UnderGarmentsImage}
            alt="/"
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
